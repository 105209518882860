import { signOut } from 'firebase/auth';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import auth from '../../firebase.init';
import logo1 from "../../assets/images/logo4.png"

const Navbar = () => {
     
    const [user] = useAuthState(auth);

    const logout = () => {
        signOut(auth);
        localStorage.removeItem('accessToken');
      }

    const menuItems = <>
        <li><Link to = "/home">Home </Link></li>
        <li><Link to = "/appointment">Appointment </Link></li>
        <li><Link to = "/doctors">Doctors </Link></li>
        <li><Link to = "/reviews">Reviews </Link></li>
        <li><Link to = "/contact">Contact </Link></li>
        <li><Link to = "/about">About </Link></li>
        <li><Link to = "/blog">Blog </Link></li>
        {
            user && <li><Link to = "/dashboard">Dashboard </Link></li>
        }
        <li>{ user ? <button onClick={logout} className="btn btn-ghost">Sign Out</button> :<Link to = "/login">Login </Link>}</li>
    </>
    return (

        <div className="navbar fixed top-0 z-10 bg-base-100 ">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex="0"className="btn btn-ghost md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg"className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex="0"className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        {menuItems}
                    </ul>
                </div>
                <Link to= "/">
                    <img src={logo1}  className = " w-16 h-16" alt="" />
                {/* <a className="btn btn-ghost normal-case text-xl">Doctor's Care</a> */}
                </Link>
               
            </div>
            <div className="navbar-center hidden md:flex">
                <ul className="menu menu-horizontal p-0">
                    {menuItems}
                </ul>
            </div>
        </div>

    );
};

export default Navbar;